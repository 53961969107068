import { Divider, Drawer, List, ListItem, ListItemIcon, withStyles } from '@material-ui/core';
import { inject, observer } from 'mobx-react';
import Router from 'next/router';
import React, { useState } from 'react';
import InlineSVG from 'react-inlinesvg';
import AppController from 'base/App.controller';
import { ADMIN, BNPP } from 'constants/banks';
import { UNDER_RESELLER, SUB_TPP } from 'constants/clientType';
import { PARTNER, REFUND, VIRTUAL_ACCOUNT } from 'constants/memberPermissions';
import { BANK_ADMIN, SUPER_ADMIN, PAYMENT_OPS } from 'constants/roles';
import chevronDown from 'images/chevron-down.svg';
import chevronUp from 'images/chevron-up.svg';
import dashboard from 'images/dashboard.svg';
import externalLink from 'images/external-link.svg';
import metrics from 'images/metrics.svg';
import sandbox from 'images/sandbox.svg';
import settings from 'images/settings.svg';
import users from 'images/users.svg';
import DrawerController from './Drawer.controller';
import DrawerItem from './DrawerItem';
import { IDrawerWrapper } from '../types';
import type { AppStore } from 'AppStore';
import { ILink, ILinks, ISubLink, Itranslation } from 'types';

const { publicRuntimeConfig } = require('next/config').default();
const { externalLinks, adminLinksOktaGroups } = publicRuntimeConfig;

const SETTINGS = 'settings';
const SUB_TPP_MANAGER = 'subTppManager';
const CERTIFICATE_MANAGEMENT = 'certificateManagement';
const REFUNDS = 'refund';
const BANK_CONNECTION_HEALTH = 'bankConnectionHealth';
const VIRTUAL_ACCOUNTS_MANAGER = 'virtualAccounts';

const ICON = {
    dashboard,
    sandbox,
    metrics,
    users,
    settings,
};

const styles = () => ({
    root: {
        fontFamily: '"DM Sans", "Source Sans Pro", sans-serif',
        minHeight: '100vh',
        display: 'flex',
        '& .toolbarOffset': {
            minHeight: '140px',
        },
        '& .dropdownIcon': {
            marginLeft: 'auto',
        },
        '& .bottomSection': {
            margin: '35px 50px',
            position: 'sticky',
            top: '100vh',
            '& .externalResources': {
                margin: '25px 0',
                '& .icon': {
                    fill: 'var(--primary)',
                },
            },
            '& .bottomHeader': {
                textTransform: 'uppercase',
                letterSpacing: '0.12em',
                fontWeight: 'bold',
                fontSize: 'var(--fs-small)',
                marginLeft: '10px',
            },
            '& .linkContainer': {
                margin: '15px 0',
                '& > div, & a': {
                    color: 'var(--spun-pearl)',
                    textDecoration: 'none',
                    fontSize: 'var(--fs-medium)',
                    letterSpacing: '0.02em',
                },
                '& .subLinks': {
                    marginLeft: '20px',
                    marginTop: '5px',
                    '& a': {
                        fontSize: '14px',
                    },
                },
            },
        },
        '& .nestedLinksContainer': {
            width: '100%',
        },
        '& .nestedLinks': {
            color: 'var(--revolver)',
            textDecoration: 'none',
            padding: '15px 0 15px 55px',
            fontSize: '14px',
            cursor: 'pointer',
            '&.Mui-disabled': {
                cursor: 'default',
                pointerEvents: 'none',
            },
        },
        '& .subLinkText': {
            fontSize: '14px',
            letterSpacing: '0.02em',
            marginLeft: '80px',
        },
    },
});

const DrawerWrapper: React.FC<IDrawerWrapper> = ({ AppStore, disabled, permissions, classes }) => {
    const { scope, links, oktaGroups } = AppStore as AppStore;
    const { parentLinks, nestedLinks } = links as ILinks;
    const { t }: Itranslation = AppController.getTranslation(scope, 'common');

    const parentItemSelected = Object.values(parentLinks).find((item) => item.selected) || {};

    const isAccessToGroup = (key: string) => {
        let isAvailable = true;
        if (
            scope === ADMIN &&
            adminLinksOktaGroups[key] &&
            adminLinksOktaGroups[key].groups.length
        ) {
            isAvailable = adminLinksOktaGroups[key].groups.some(
                (group: string) => oktaGroups.indexOf(group) >= 0,
            );
        }
        return isAvailable;
    };

    const [ctx, setCtx] = useState({
        isSettingsOpen: !(parentItemSelected as ILink).link && scope !== ADMIN,
        isBankConnectionHealthOpen: !(parentItemSelected as ILink).link,
    });

    // handled certificate management nested sublinks.
    const certNestedSubLinks = nestedLinks[CERTIFICATE_MANAGEMENT]?.subLinks;
    const nestedSubLinkSelected = certNestedSubLinks
        ? Object.values(certNestedSubLinks).find((item) => item.selected) || {}
        : {};
    const [isCertificateManagementOpen, setCertificateManagement] = useState(
        Object.keys(nestedSubLinkSelected).length > 0,
    );

    const initRouteOpen = () => {
        const routeOpen: { [key: string]: boolean } = {};
        Object.keys(parentLinks).map((key) => {
            routeOpen[key] = !!(parentItemSelected as ILink).subLinks;
        });

        return routeOpen;
    };

    const [isRouteOpen, setIsRouteOpen] = useState(initRouteOpen());

    const handleSelection = async (event: React.SyntheticEvent) => {
        event.preventDefault();
        const key = event.currentTarget.getAttribute('name');
        if (key === null) {
            throw Error('name attribute missing');
        }

        setCtx((ctx) => {
            switch (key) {
                case SETTINGS:
                    return {
                        ...ctx,
                        isSettingsOpen: !ctx.isSettingsOpen,
                    };
                case BANK_CONNECTION_HEALTH:
                    return {
                        ...ctx,
                        isBankConnectionHealthOpen: !ctx.isBankConnectionHealthOpen,
                    };
                default:
                    return ctx;
            }
        });

        if (parentLinks[key].showCarat) {
            const tempRoute = isRouteOpen;
            tempRoute[key] = !tempRoute[key];
            setIsRouteOpen(tempRoute);
        } else {
            const isTopLevel = Router.route.match(/\//g)?.length === 1;

            if (parentLinks[key].selected && isTopLevel) {
                return Router.reload();
            }
        }

        if (parentLinks[key].link) await Router.push(parentLinks[key].link);
    };

    const handleSubSelection = async (event: React.SyntheticEvent) => {
        event.preventDefault();
        const key = event.currentTarget.getAttribute('name');
        if (key === null) {
            throw Error('name attribute missing!');
        }

        if (key === CERTIFICATE_MANAGEMENT && nestedLinks[key].subLinks) {
            !isCertificateManagementOpen
                ? setCertificateManagement(true)
                : setCertificateManagement(false);
            return;
        }

        if (nestedLinks[key].selected) {
            return Router.reload();
        }

        await Router.push(nestedLinks[key].link);
    };

    const handleSubLinkSelection = async (event: React.SyntheticEvent, parentKey: string) => {
        const key = event.currentTarget.getAttribute('name');
        const { parentLinks } = AppStore.links;
        event.preventDefault();
        if (key === null) {
            throw Error('name attribute missing!');
        }

        const subLinks = parentLinks[parentKey].subLinks;
        if (subLinks && subLinks[key].selected) {
            return Router.reload();
        }

        await Router.push(subLinks![key].link);
    };

    const handleNestedSubLinkSelection = async (event: React.SyntheticEvent, parentKey: string) => {
        event.preventDefault();
        const key = event.currentTarget.getAttribute('name');
        if (key === null) {
            throw Error('name attribute missing!');
        }

        const subLinks = nestedLinks[parentKey].subLinks;
        if (subLinks && subLinks[key].selected) {
            return Router.reload();
        }

        await Router.push(subLinks ? subLinks[key].link : '');
    };

    const filteredSubLinks = (subLinks: { [key: string]: ISubLink }) => {
        return Object.keys(subLinks).filter(
            (key) => !subLinks[key].disabled && !subLinks[key].hidden,
        );
    };

    const filteredNestedLinks = Object.keys(nestedLinks).filter(
        (key) => !nestedLinks[key].disabled && !nestedLinks[key].hidden,
    );

    // APE-2195 - #2
    // Activity menu option for Merchant under Reseller in Production should not be shown
    // APE-2302
    // Front End Implementation - Sandbox dashboards under /activity don't show any data
    if (
        (publicRuntimeConfig.tokenEnv === 'sandbox' && AppStore.user.role === PAYMENT_OPS) ||
        (publicRuntimeConfig.features.prod &&
            [UNDER_RESELLER, SUB_TPP].includes(AppStore.client.clientType))
    ) {
        delete parentLinks['activity'];
    }

    const filteredLinks = Object.keys(parentLinks).filter(
        (key) =>
            !parentLinks[key].disabled &&
            !parentLinks[key].hidden &&
            isAccessToGroup(key) &&
            (key !== SUB_TPP_MANAGER ||
                ([UNDER_RESELLER, SUB_TPP].includes(AppStore.client.clientType) &&
                    permissions.includes(PARTNER))) &&
            (key !== VIRTUAL_ACCOUNTS_MANAGER || permissions.includes(VIRTUAL_ACCOUNT)) &&
            (key !== SETTINGS || !!filteredNestedLinks.length),
    );

    const filterNestedSubLinks = (tab: string) => {
        const subLinks = nestedLinks[tab]?.subLinks || {};

        return Object.keys(subLinks).filter((key) => {
            const subLink = subLinks[key];
            const isRefundLink = key === REFUNDS;
            const hasRefundPermission = permissions.includes(REFUND);
            const isPaymentOpsInBNPP = AppStore.user.role === PAYMENT_OPS && scope === BNPP;
            const isNotTypeOneMember = AppStore.member.memberType !== 'TYPE_ONE';

            const shouldIncludeRefundLink =
                isRefundLink && hasRefundPermission && (isPaymentOpsInBNPP || scope !== BNPP);

            const shouldIncludeOtherLinks =
                !isRefundLink && !subLink.disabled && !subLink.hidden && isNotTypeOneMember;

            return shouldIncludeRefundLink || shouldIncludeOtherLinks;
        });
    };

    const getSublinkItem = (key: string, k: string): JSX.Element => {
        const isSelected = (parentLinks[key].subLinks as { [key: string]: ISubLink })[k].selected;
        return (
            <ListItem
                button
                className="subListItem"
                disabled={disabled}
                onMouseDown={(e: React.SyntheticEvent) => handleSubLinkSelection(e, key)}
                onKeyPress={(e: React.SyntheticEvent) => handleSubLinkSelection(e, key)}
                //@ts-ignore
                name={k} //! prop doesn't exist on ListItem
                selected={isSelected}
                key={k}>
                <div className={'subLinkText'}>
                    <span>{t(k)}</span>
                </div>
            </ListItem>
        );
    };

    return (
        <Drawer variant="permanent" anchor="left" className={classes.root}>
            <div className="toolbarOffset" />
            <List>
                {filteredLinks.map((key) => (
                    <div key={key}>
                        <DrawerItem
                            className="listItem"
                            disabled={disabled}
                            onMouseDown={handleSelection}
                            onKeyPress={handleSelection}
                            name={key}
                            selected={parentLinks[key].selected}>
                            <span className="selectedDivider" />
                            <ListItemIcon>
                                <InlineSVG
                                    src={ICON[parentLinks[key].iconName as keyof typeof ICON]}
                                    className="icon"
                                />
                            </ListItemIcon>
                            <div className="listText">
                                <span>{t(key)}</span>
                            </div>
                            {(key === SETTINGS || parentLinks[key].showCarat) &&
                                (ctx.isSettingsOpen ||
                                (parentLinks[key].selected && isRouteOpen[key]) ? (
                                    <img src={chevronUp} className="dropdownIcon" />
                                ) : (
                                    <img src={chevronDown} className="dropdownIcon" />
                                ))}
                        </DrawerItem>
                        {key !== BANK_CONNECTION_HEALTH &&
                            parentLinks[key].selected &&
                            parentLinks[key].subLinks &&
                            isRouteOpen[key] &&
                            filteredSubLinks(parentLinks[key].subLinks!).map((k) =>
                                getSublinkItem(key, k),
                            )}

                        {ctx.isBankConnectionHealthOpen &&
                            key === BANK_CONNECTION_HEALTH &&
                            filteredSubLinks(parentLinks[key].subLinks!).map((k) =>
                                getSublinkItem(key, k),
                            )}
                    </div>
                ))}

                {ctx.isSettingsOpen && (
                    <ListItem>
                        <List className={'nestedLinksContainer'}>
                            {filteredNestedLinks.map((key) => (
                                <div key={key}>
                                    <ListItem
                                        button
                                        key={key}
                                        onMouseDown={handleSubSelection}
                                        onKeyPress={handleSubSelection}
                                        //@ts-ignore
                                        name={key} //! prop doesn't exist on ListItem(Mui) check if breaks
                                        className={'nestedLinks'}
                                        selected={nestedLinks[key].selected}
                                        disabled={disabled}>
                                        <div>{t(key)}</div>
                                        {key === CERTIFICATE_MANAGEMENT &&
                                        nestedLinks[key].subLinks ? (
                                            isCertificateManagementOpen ? (
                                                <img src={chevronUp} className="dropdownIcon" />
                                            ) : (
                                                <img src={chevronDown} className="dropdownIcon" />
                                            )
                                        ) : (
                                            ''
                                        )}
                                    </ListItem>
                                    {isCertificateManagementOpen &&
                                        key === CERTIFICATE_MANAGEMENT &&
                                        nestedLinks[key].subLinks &&
                                        filterNestedSubLinks(key).map((kt) => (
                                            <ListItem
                                                button
                                                className={'subListItem nestedSubListItem'}
                                                disabled={disabled}
                                                selected={
                                                    (nestedLinks[key].subLinks as {
                                                        [key: string]: ISubLink;
                                                    })[kt].selected
                                                }
                                                onMouseDown={(e: React.SyntheticEvent) =>
                                                    handleNestedSubLinkSelection(e, key)
                                                }
                                                onKeyPress={(e) =>
                                                    handleNestedSubLinkSelection(e, key)
                                                }
                                                //@ts-ignore
                                                name={kt} //! prop doesn't exist on ListItem check if breaks
                                                key={kt}>
                                                <div className={'subLinkText'}>
                                                    <span>{t(kt)}</span>
                                                </div>
                                            </ListItem>
                                        ))}
                                </div>
                            ))}
                        </List>
                    </ListItem>
                )}
                {scope !== ADMIN && (
                    <div className="bottomSection">
                        <Divider />

                        <div className="externalResources">
                            <InlineSVG src={externalLink} className="icon" />
                            <span className="bottomHeader">{t('externalResources')}</span>
                        </div>

                        {AppStore.user.role !== BANK_ADMIN && (
                            <>
                                {DrawerController.getExternalLinks(AppStore, permissions, t)}
                                {![UNDER_RESELLER, SUB_TPP].includes(AppStore.client.clientType) &&
                                    AppStore.scope !== BNPP && (
                                        <div className="linkContainer">
                                            <a
                                                href={externalLinks.releaseNotes}
                                                target="_blank"
                                                rel="noopener noreferrer">
                                                {t('releaseNotes')}
                                            </a>
                                        </div>
                                    )}
                            </>
                        )}
                        {AppStore.user.role !== SUPER_ADMIN &&
                            ![UNDER_RESELLER, SUB_TPP].includes(AppStore.client.clientType) &&
                            AppStore.scope !== BNPP && (
                                <>
                                    <div className="linkContainer">
                                        <a
                                            href={externalLinks.support.default}
                                            target="_blank"
                                            rel="noopener noreferrer">
                                            {t('support')}
                                        </a>
                                    </div>
                                </>
                            )}
                    </div>
                )}
            </List>
        </Drawer>
    );
};

export default withStyles(styles, { name: 'DrawerWrapper' })(
    inject('AppStore')(observer(DrawerWrapper)),
);
