import axios from 'axios';
import { action, computed, configure, flow, observable, toJS } from 'mobx';
import { useStaticRendering } from 'mobx-react';
import moment from 'moment';

import ColorPickerStore from '../css/color-picker/ColorPickerStore';
import ModalStore from 'components/common/modals/ModalStore';

// constants
import { RESELLER } from 'constants/clientType';
import { getDefaultCss } from 'constants/defaultCss';
import { getLegalTextMessages } from 'constants/legalTextMessages';
import {
    CUSTOM,
    LEGAL_TEXT_TYPES,
    NO_FOOTER_LOGO,
    TRUST_LOGOS,
    footerType,
} from 'constants/webAppV2';

// helpers
import asyncReadFile from 'helpers/async-read-file/asyncReadFile';
import _isEmpty from 'lodash/isEmpty';
import _merge from 'lodash/merge';

// types
import { AsyncGeneratorReturnType, ITPPFeatureControl } from 'types';
import type ConfigurationStore from 'components/configuration/ConfigurationStore';
import IConfigurations, { LegalTextTypes, LogoType, SupportedLanguages } from './types';
import asyncImageLoad from 'helpers/async-image-load/asyncImageLoad';

const isServer = typeof window === 'undefined';
useStaticRendering(isServer);
configure({
    enforceActions: 'observed',
});

class WebAppV2Store {
    constructor(public rootStore: ConfigurationStore) {
        this.rootStore = rootStore;
    }
    GenericSuccessStore = new ModalStore();
    GenericErrorStore = new ModalStore();
    displayName = '';
    helpTextFields = [
        'enableTransactionStatusPage',
        'showDefaultQrCode',
        'enableOnboardingScreen',
        'enableFeedbackSurvey',
    ];
    defaultSessionDetailsSettings: {
        [key: string]: boolean;
    } = {
        showRemittanceInformationSecondary: true,
        showCreditorAccountName: false,
        showCreditorAccountIdentifier: false,
        showCreditorBankIdentifier: false,
        showDebtorAccountIdentifier: false,
        showDebtorBankIdentifier: false,
    };
    defaultFlowCustomizations: {
        [key: string]: boolean;
    } = {
        enableOnboardingScreen: true,
        enableFeedbackSurvey: false,
        enableTransactionStatusPage: false,
        enableAutoMode: true,
        showDefaultQrCode: false,
    };
    defaultLegalTexts = getLegalTextMessages;
    defaultLegalForm = {
        displayName: '',
        businessName: 'Token',
        expirationDate: moment(new Date()).add(90, 'day').format('MMMM Do YYYY'),
        ...this.defaultLegalTexts,
    };
    defaultColorOptions: {
        [key: string]: {
            light: {
                value: string;
                colorPickerStore: ColorPickerStore;
            };
            dark: {
                value: string;
                colorPickerStore: ColorPickerStore;
            };
        };
    } = {
        accentColor: {
            light: {
                value: getDefaultCss['color-light-accent'],
                colorPickerStore: new ColorPickerStore(),
            },
            dark: {
                value: getDefaultCss['color-dark-accent'],
                colorPickerStore: new ColorPickerStore(),
            },
        },
        secondaryAccentColor: {
            light: {
                value: getDefaultCss['color-light-secondary-accent'],
                colorPickerStore: new ColorPickerStore(),
            },
            dark: {
                value: getDefaultCss['color-dark-secondary-accent'],
                colorPickerStore: new ColorPickerStore(),
            },
        },
        successColor: {
            light: {
                value: getDefaultCss['color-light-status-success'],
                colorPickerStore: new ColorPickerStore(),
            },
            dark: {
                value: getDefaultCss['color-dark-status-success'],
                colorPickerStore: new ColorPickerStore(),
            },
        },
        submittedColor: {
            light: {
                value: getDefaultCss['color-light-status-submitted'],
                colorPickerStore: new ColorPickerStore(),
            },
            dark: {
                value: getDefaultCss['color-dark-status-submitted'],
                colorPickerStore: new ColorPickerStore(),
            },
        },
        failureColor: {
            light: {
                value: getDefaultCss['color-light-status-failure'],
                colorPickerStore: new ColorPickerStore(),
            },
            dark: {
                value: getDefaultCss['color-dark-status-failure'],
                colorPickerStore: new ColorPickerStore(),
            },
        },
    };
    emptyLogo = {
        data: '',
    };

    @observable
    loadingSaveButton = false;
    @observable
    language: SupportedLanguages = 'en';
    @observable
    legalForm = this.defaultLegalForm;
    @observable
    flowCustomisationList = { ...this.defaultFlowCustomizations };
    @observable
    sessionDetailsSettingsList = { ...this.defaultSessionDetailsSettings };
    @observable
    colorOptions: {
        [key: string]: {
            light: {
                value: string;
                colorPickerStore: ColorPickerStore;
            };
            dark: {
                value: string;
                colorPickerStore: ColorPickerStore;
            };
        };
    } = { ...this.defaultColorOptions };
    @observable
    hasAnyLogoChanged = false;
    @observable
    tppLogoLight = this.emptyLogo;
    @observable
    tppLogoDark = this.emptyLogo;
    @observable
    footerLogoLight = this.emptyLogo;
    @observable
    footerLogoDark = this.emptyLogo;
    @observable
    footerType: footerType = TRUST_LOGOS;
    @observable
    showLightTppLogoError: boolean = false;
    @observable
    showDarkTppLogoError: boolean = false;
    @observable
    showLightFooterLogoError: boolean = false;
    @observable
    showDarkFooterLogoError: boolean = false;
    @observable
    tosContent: { [key: string]: string } = {};
    @observable
    ppContent: { [key: string]: string } = {};
    @observable
    disableSaveButton: boolean = false;
    @observable
    updatedConfigurations: IConfigurations = {};
    @observable
    fetchedConfigurations: IConfigurations = {};
    @observable
    customLogosNotUploaded: boolean = false;
    @observable
    isLegalTextEditable = true;

    @action
    setLanguage = (lang: SupportedLanguages) => {
        this.language = lang;
    };

    @computed
    get enableSave(): boolean {
        return (
            !this.disableSaveButton &&
            (Object.keys(toJS(this.updatedConfigurations)).length != 0 || this.hasAnyLogoChanged)
        );
    }

    @action
    restoreLegalTextToDefault = (type: LegalTextTypes) => {
        this.handleTranslationChange(type, this.defaultLegalTexts[type][this.language]);
    };

    @action
    handleBusinessNameChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const value = e.target.value;
        this.legalForm[e.target.name as 'businessName'] = value;
        if (this.fetchedConfigurations.businessName !== value) {
            this.updatedConfigurations.businessName = value;
        } else {
            delete this.updatedConfigurations?.businessName;
        }
    };

    @action
    populateLegalTextsLoaders = () => {
        this.legalForm = this.defaultLegalForm;
    };

    @action
    setFooterType = (footerType: footerType) => {
        this.footerType = footerType;
        this.customLogosNotUploaded = false;

        this.updatedConfigurations.enableTrustIcons = {
            value: this.footerType === TRUST_LOGOS,
        };
    };

    @action
    setLogo = (type: string, data: string, logoName: LogoType) => {
        this[logoName] = {
            data: `data:${type};base64,${data}`,
        };
    };

    @action
    setLogos = async () => {
        const { darkMode, lightMode } = this.fetchedConfigurations;
        const logos: Record<string, any> = {};
        const fileReferences = {
            tppLogoDark: darkMode?.logoReference ?? '',
            tppLogoLight: lightMode?.logoReference ?? '',
            footerLogoLight: lightMode?.footerLogoReference ?? '',
            footerLogoDark: darkMode?.footerLogoReference ?? '',
        };
        const logoPromises = Object.keys(fileReferences).map(async (reference) => {
            if (fileReferences[reference as LogoType]) {
                logos[reference] = await this.getLogo(fileReferences[reference as LogoType]);
            }
        });
        await Promise.all(logoPromises);
        Object.keys(logos).map((logo) => {
            if (logos[logo]?.data) {
                const { data, type } = logos[logo];
                this.setLogo(type, data, logo as LogoType);
            }
        });
    };

    @computed
    get isTppLogoLightUploaded() {
        return !!this.tppLogoLight.data;
    }

    @computed
    get isTppLogoDarkUploaded() {
        return !!this.tppLogoDark.data;
    }

    @computed
    get isFooterLogoLightUploaded() {
        return !!this.footerLogoLight.data;
    }

    @computed
    get isFooterLogoDarkUploaded() {
        return !!this.footerLogoDark.data;
    }

    @action
    setDefaultConfigurations = () => {
        const defaultSessionAndFlowSettings: {
            [key: string]: boolean;
        } = {
            ...this.defaultSessionDetailsSettings,
            ...this.defaultFlowCustomizations,
            enableTrustIcons: true,
        };
        Object.keys(defaultSessionAndFlowSettings).forEach((key) => {
            if (typeof this.fetchedConfigurations[key]?.value === 'boolean') {
                this.updatedConfigurations[key] = {
                    value: defaultSessionAndFlowSettings[key],
                };
            }
        });
        LEGAL_TEXT_TYPES.forEach((type) => {
            const fetchedLegalTranslations = this.fetchedConfigurations[type];
            if (!_isEmpty(fetchedLegalTranslations)) {
                const legalTranslations = this.legalForm[type];
                Object.keys(legalTranslations).forEach((key) => {
                    if (key in fetchedLegalTranslations) {
                        this.updatedConfigurations[type] = {
                            ...this.updatedConfigurations[type],
                            [key]: legalTranslations[key as SupportedLanguages],
                        };
                    }
                });
            }
        });
        this.updatedConfigurations['businessName'] = 'Token';
        ['termsAndConditions', 'privacyPolicy'].forEach((type) => {
            if (!_isEmpty(this.fetchedConfigurations[type])) {
                Object.keys(this.fetchedConfigurations[type]).forEach((key) => {
                    this.updatedConfigurations[type] = {
                        ...this.updatedConfigurations[type],
                        [key]: '',
                    };
                });
            }
        });
        ['lightMode', 'darkMode'].forEach((type) => {
            const theme = type === 'lightMode' ? 'light' : 'dark';
            const themeConfig = this.fetchedConfigurations[type] ?? {};
            if (!_isEmpty(themeConfig)) {
                Object.keys(themeConfig).forEach((key) => {
                    if (themeConfig[key]) {
                        const colorName = this.defaultColorOptions[key];
                        this.updatedConfigurations[type] = {
                            ...this.updatedConfigurations[type],
                            [key]: colorName?.[theme].value ?? '',
                        };
                    }
                });
            }
        });
    };

    @action
    fetchConfigurations = async (memberId: string, subTppId: string) => {
        let url = `/api/webappPortal/v2/configurations/${memberId}`;
        url += subTppId ? `?subTppId=${subTppId}` : '';
        return await axios.get(url);
    };

    @action
    getLogo = async (fileReference: string) => {
        const response = await axios.get(`/api/webappPortal/v2/get-logo/${fileReference}`);
        return response.data.file;
    };

    @action
    uploadLogo = async (
        logo: { [key: string]: string },
        logoName: string,
        memberId: string,
        subTppId: string,
    ) => {
        const response = await axios.post('/api/webappPortal/v2/upload-logo', {
            logo,
            memberId,
            subTppId,
            logoName,
        });
        return response.data.fileReference;
    };

    @action
    fetchFeatureControl = flow(
        function* (
            this: WebAppV2Store,
            memberId: string,
        ): AsyncGeneratorReturnType<ITPPFeatureControl> {
            try {
                const response = yield axios.get(`/api/tppIntegration/feature-control/${memberId}`);
                this.isLegalTextEditable = response.data.consentTC;
            } catch (err) {
                console.error(err);
            }
        }.bind(this),
    );

    @action
    fetchDisplayName = flow(
        function* (this: WebAppV2Store, memberId: string): AsyncGeneratorReturnType {
            try {
                const response = yield axios.get(`/api/member/${memberId}/profile`);
                this.displayName = response.data.profileName;
            } catch (err) {
                console.error(err);
            }
        }.bind(this),
    );

    @action
    fetchAndSaveConfigurations = flow(
        function* (): AsyncGeneratorReturnType {
            const { memberId, refId: subTppId, clientType } = this.rootStore;
            if (clientType !== RESELLER) {
                this.isLegalTextEditable = false;
                this.fetchFeatureControl(memberId);
            }
            this.fetchDisplayName(memberId);
            const res = yield this.fetchConfigurations(memberId, subTppId);
            const {
                tppConfiguration: { configuration },
            } = res.data;
            this.fetchedConfigurations = configuration;
            for (const key in this.flowCustomisationList) {
                if (typeof this.fetchedConfigurations?.[key]?.value === 'boolean') {
                    this.flowCustomisationList[key] = this.fetchedConfigurations[key]?.value;
                }
            }
            for (const key in this.sessionDetailsSettingsList) {
                if (typeof this.fetchedConfigurations?.[key]?.value === 'boolean') {
                    this.sessionDetailsSettingsList[key] = this.fetchedConfigurations[key]?.value;
                }
            }
            for (const key in this.colorOptions) {
                const lightValue =
                    this.fetchedConfigurations?.lightMode?.[key] ??
                    this.colorOptions[key].light.value;
                const darkValue =
                    this.fetchedConfigurations?.darkMode?.[key] ??
                    this.colorOptions[key].dark.value;
                lightValue !== '' && (this.colorOptions[key].light.value = lightValue);
                darkValue !== '' && (this.colorOptions[key].dark.value = darkValue);
            }
            this.tosContent = this.fetchedConfigurations?.termsAndConditions;
            this.ppContent = this.fetchedConfigurations?.privacyPolicy;
            const { lightMode, darkMode, enableTrustIcons } = this.fetchedConfigurations;
            if (lightMode?.footerLogoReference || darkMode?.footerLogoReference) {
                this.footerType = CUSTOM;
            } else if (enableTrustIcons?.value === false) {
                this.footerType = NO_FOOTER_LOGO;
            } else {
                this.footerType = TRUST_LOGOS;
            }
            for (const type of LEGAL_TEXT_TYPES) {
                this.legalForm = {
                    ...this.legalForm,
                    [type]: {
                        ...this.legalForm[type],
                        ...this.fetchedConfigurations?.[type],
                    },
                };
            }
            this.legalForm.displayName = this.displayName;
            this.legalForm.businessName = this.fetchedConfigurations.businessName || 'Token';
            yield this.setLogos();
        }.bind(this),
    );

    @action
    handleLogoUpload = flow(
        function* (event: { target: { name: any; files: any[] } }) {
            const name = event.target.name as string;
            const file = event.target.files[0];

            try {
                const data = (yield asyncReadFile(file, true)) as string;
                const maxSizeAllowed = 1 * 1024 * 1024;
                const isFileAcceptable = file.size <= maxSizeAllowed;

                if (isFileAcceptable) {
                    const image = new Image();
                    image.src = data;

                    yield asyncImageLoad(image);

                    const targetHeight = name.includes('footer') ? 20 : 40;
                    const aspectRatio = image.width / image.height;
                    const newWidth = Math.round(targetHeight * aspectRatio);

                    const canvas = document.createElement('canvas');
                    const ctx = canvas.getContext('2d') as CanvasRenderingContext2D;

                    canvas.width = newWidth;
                    canvas.height = targetHeight;
                    ctx.drawImage(image, 0, 0, newWidth, targetHeight);
                    const resizedData = canvas.toDataURL(file.type);

                    action(() => {
                        if (name === 'tppLogoLight') {
                            this.showLightTppLogoError = false;
                            this.tppLogoLight = {
                                data: resizedData,
                                mimeType: file.type,
                            };
                        } else if (name === 'tppLogoDark') {
                            this.showDarkTppLogoError = false;
                            this.tppLogoDark = {
                                data: resizedData,
                                mimeType: file.type,
                            };
                        } else if (name === 'footerLogoLight') {
                            this.showLightFooterLogoError = false;
                            this.footerLogoLight = {
                                data: resizedData,
                                mimeType: file.type,
                            };
                            this.customLogosNotUploaded = false;
                        } else {
                            this.showDarkFooterLogoError = false;
                            this.footerLogoDark = {
                                data: resizedData,
                                mimeType: file.type,
                            };
                            this.customLogosNotUploaded = false;
                        }
                        this.hasAnyLogoChanged = true;
                    })();
                } else {
                    action(() => {
                        if (name === 'tppLogoLight') {
                            this.showLightTppLogoError = true;
                        } else if (name === 'tppLogoDark') {
                            this.showDarkTppLogoError = true;
                        } else if (name === 'footerLogoLight') {
                            this.showLightFooterLogoError = true;
                        } else {
                            this.showDarkFooterLogoError = true;
                        }
                    })();
                }
            } catch (error) {
                console.error('Error in handleLogoUpload:', error);
                action(() => {
                    if (name === 'tppLogoLight') {
                        this.showLightTppLogoError = true;
                    } else if (name === 'tppLogoDark') {
                        this.showDarkTppLogoError = true;
                    } else if (name === 'footerLogoLight') {
                        this.showLightFooterLogoError = true;
                    } else {
                        this.showDarkFooterLogoError = true;
                    }
                })();
            }
        }.bind(this),
    );

    @action
    handleLightColorChange = (event: { target: { name: string; value: string } }) => {
        const colorName = event.target.name;
        const value = event.target.value;
        this.colorOptions[colorName].light.value = value;
        if (this.fetchedConfigurations?.lightMode?.[colorName] !== value) {
            if (!this.updatedConfigurations.lightMode) {
                this.updatedConfigurations.lightMode = {};
            }
            this.updatedConfigurations.lightMode[colorName] = value;
        } else {
            delete this.updatedConfigurations?.lightMode?.[colorName];
            if (
                this.updatedConfigurations.lightMode &&
                Object.keys(this.updatedConfigurations.lightMode).length === 0
            ) {
                delete this.updatedConfigurations.lightMode;
            }
        }
    };

    @action
    handleDarkColorChange = (event: { target: { name: string; value: string } }) => {
        const colorName = event.target.name;
        const value = event.target.value;
        this.colorOptions[colorName].dark.value = value;
        if (this.fetchedConfigurations?.darkMode?.[colorName] !== value) {
            if (!this.updatedConfigurations.darkMode) {
                this.updatedConfigurations.darkMode = {};
            }
            this.updatedConfigurations.darkMode[colorName] = value;
        } else {
            delete this.updatedConfigurations?.darkMode?.[colorName];
            if (
                this.updatedConfigurations.darkMode &&
                Object.keys(this.updatedConfigurations.darkMode).length === 0
            ) {
                delete this.updatedConfigurations.darkMode;
            }
        }
    };

    @action
    handleResetLogo = (logoType: String) => {
        switch (logoType) {
            case 'tppLight':
                this.tppLogoLight = this.emptyLogo;
                this.updatedConfigurations.lightMode = {
                    ...this.updatedConfigurations['lightMode'],
                    logoReference: '',
                };
                this.showLightTppLogoError = false;
                break;
            case 'tppDark':
                this.tppLogoDark = this.emptyLogo;
                this.updatedConfigurations.darkMode = {
                    ...this.updatedConfigurations['darkMode'],
                    logoReference: '',
                };
                this.showDarkTppLogoError = false;
                break;
            case 'footerLogos':
                this.footerLogoLight = this.footerLogoDark = this.emptyLogo;
                this.showLightFooterLogoError = this.showDarkFooterLogoError = false;
                break;
        }
    };

    @action
    addLogoInConfigurations = (mode: string, type: string, reference: string) => {
        if (reference) {
            this.updatedConfigurations[mode] = {
                ...this.updatedConfigurations[mode],
                [type]: reference,
            };
        }
    };

    @action
    setFileReference = async () => {
        const logos = {
            tppLogoDark: this.tppLogoDark,
            tppLogoLight: this.tppLogoLight,
            footerLogoLight: this.footerLogoLight,
            footerLogoDark: this.footerLogoDark,
        };
        const fileReferences: {
            [key: string]: string;
        } = {
            tppLogoLight: '',
            tppLogoDark: '',
            footerLogoLight: '',
            footerLogoDark: '',
        };
        const logoPromises = Object.keys(logos).map(async (logo) => {
            fileReferences[logo] = await this.uploadLogo(
                logos[logo as keyof typeof logos],
                logo,
                this.rootStore.memberId,
                this.rootStore.refId,
            );
        });
        await Promise.all(logoPromises);
        for (const mode of ['lightMode', 'darkMode']) {
            this.addLogoInConfigurations(
                mode,
                'logoReference',
                fileReferences[`tppLogo${mode === 'darkMode' ? 'Dark' : 'Light'}`],
            );
            this.addLogoInConfigurations(
                mode,
                'footerLogoReference',
                fileReferences[`footerLogo${mode === 'darkMode' ? 'Dark' : 'Light'}`],
            );
        }
    };

    @action
    handleSave = flow(
        function* (): any {
            try {
                if (
                    this.footerType === CUSTOM &&
                    (!this.footerLogoLight.data || !this.footerLogoDark.data)
                ) {
                    this.customLogosNotUploaded = true;
                } else {
                    this.customLogosNotUploaded = false;
                    this.loadingSaveButton = true;
                    this.disableSaveButton = true;
                    yield this.setFileReference();
                    const finalConfiguration = _merge(
                        { ...this.fetchedConfigurations },
                        { ...this.updatedConfigurations },
                    );

                    if (this.footerType !== CUSTOM) {
                        finalConfiguration.lightMode &&
                            (finalConfiguration.lightMode.footerLogoReference = '');
                        finalConfiguration.darkMode &&
                            (finalConfiguration.darkMode.footerLogoReference = '');
                    }
                    const res = yield axios.post('/api/webappPortal/v2/configurations', {
                        memberId: this.rootStore.memberId,
                        subTppId: this.rootStore.refId,
                        configuration: finalConfiguration,
                    });
                    const {
                        tppConfiguration: { configuration },
                    } = res.data;
                    this.fetchedConfigurations = configuration;
                    this.updatedConfigurations = {};
                    this.hasAnyLogoChanged = false;
                    this.GenericSuccessStore.openModal();
                }
            } catch (e) {
                console.error(e);
                this.GenericErrorStore.openErrorModal(e);
            } finally {
                this.loadingSaveButton = false;
                this.disableSaveButton = false;
            }
        }.bind(this),
    );

    @action
    handleTranslationChange = (name: string, value: string) => {
        this.legalForm = {
            ...this.legalForm,
            [name]: {
                ...this.legalForm[name as LegalTextTypes],
                [this.language]: value,
            },
        };

        if (
            this.fetchedConfigurations?.[name]?.[this.language] !==
            this.legalForm[name as LegalTextTypes]?.[this.language]
        ) {
            this.updatedConfigurations = {
                ...this.updatedConfigurations,
                [name]: {
                    ...this.updatedConfigurations[name],
                    [this.language]: value,
                },
            };
        } else {
            if (this.updatedConfigurations?.[name]?.[this.language]) {
                delete this.updatedConfigurations[name][this.language];
                if (Object.keys(this.updatedConfigurations[name]).length === 0) {
                    delete this.updatedConfigurations[name];
                }
            }
        }
    };

    @action
    getEditContentPlainText = (contentType: string) => {
        if (contentType === 'editTOS') {
            return this.tosContent[this.language] || '';
        } else {
            return this.ppContent[this.language] || '';
        }
    };

    @action
    handleEditContent = (name: string, value: string) => {
        if (name === 'editTOS') {
            this.tosContent = { ...this.tosContent, [this.language]: value };
            if (this.fetchedConfigurations?.termsAndConditions?.[this.language] !== value) {
                if (!this.updatedConfigurations.termsAndConditions) {
                    this.updatedConfigurations.termsAndConditions = {};
                }
                this.updatedConfigurations.termsAndConditions[this.language] = value;
            } else {
                delete this.updatedConfigurations?.termsAndConditions?.[this.language];
                if (
                    this.updatedConfigurations.termsAndConditions &&
                    Object.keys(this.updatedConfigurations.termsAndConditions).length === 0
                ) {
                    delete this.updatedConfigurations.termsAndConditions;
                }
            }
        } else if (name === 'editPP') {
            this.ppContent = { ...this.ppContent, [this.language]: value };
            if (this.fetchedConfigurations?.privacyPolicy?.[this.language] !== value) {
                if (!this.updatedConfigurations.privacyPolicy) {
                    this.updatedConfigurations.privacyPolicy = {};
                }
                this.updatedConfigurations.privacyPolicy[this.language] = value;
            } else {
                delete this.updatedConfigurations?.privacyPolicy?.[this.language];
                if (
                    this.updatedConfigurations.privacyPolicy &&
                    Object.keys(this.updatedConfigurations.privacyPolicy).length === 0
                ) {
                    delete this.updatedConfigurations.privacyPolicy;
                }
            }
        }
    };

    @action
    resetToDefaults = () => {
        this.colorOptions = this.defaultColorOptions;
        this.language = 'en';
        this.handleResetLogo('tppLight');
        this.handleResetLogo('tppDark');
        this.handleResetLogo('footerLogos');
        this.footerType = TRUST_LOGOS;
        this.flowCustomisationList = this.defaultFlowCustomizations;
        this.sessionDetailsSettingsList = this.defaultSessionDetailsSettings;
        this.legalForm = this.defaultLegalForm;
        this.populateLegalTextsLoaders();
        this.tosContent = {};
        this.ppContent = {};
        this.hasAnyLogoChanged = false;
        this.setDefaultConfigurations();
    };

    @action
    handleEditSessionDetailsSettings = (event: React.ChangeEvent<HTMLInputElement>) => {
        const { name, checked } = event.target;
        this.sessionDetailsSettingsList[name] = checked;
        if (this.fetchedConfigurations?.[name]?.value !== checked) {
            this.updatedConfigurations[name] = {
                value: checked,
            };
        } else {
            delete this.updatedConfigurations[name];
        }
    };

    @action
    handleChangeFlowCustomisation = (event: React.ChangeEvent<HTMLInputElement>) => {
        const { name, checked } = event.target;
        this.flowCustomisationList[name] = checked;
        if (this.fetchedConfigurations?.[name]?.value !== checked) {
            if (name === 'enableTransactionStatusPage' && checked) {
                this.handleEditSessionDetailsSettings({
                    target: {
                        name: 'showDebtorAccountIdentifier',
                        checked: true,
                    },
                } as React.ChangeEvent<HTMLInputElement>);
                this.handleEditSessionDetailsSettings({
                    target: {
                        name: 'showDebtorBankIdentifier',
                        checked: true,
                    },
                } as React.ChangeEvent<HTMLInputElement>);
            }
            this.updatedConfigurations[name] = {
                value: checked,
            };
        } else {
            delete this.updatedConfigurations[name];
        }
    };
}

export default WebAppV2Store;
