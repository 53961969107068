import axios from 'axios';
import { invert, isEqual } from 'lodash';
import { action, configure, flow, observable } from 'mobx';
import { useStaticRendering } from 'mobx-react';
import ModalStore from 'components/common/modals/ModalStore';

// constants
import {
    STANDING_ORDER_STATUS_CHANGED,
    TRANSFER_STATUS_CHANGED,
    REFUND_STATUS_CHANGED,
    VRP_STATUS_CHANGED,
    VRP_CONSENT_STATUS_CHANGED,
    BANK_AIS_OUTAGE_STATUS_CHANGED,
    BANK_SIP_OUTAGE_STATUS_CHANGED,
    PAYMENT_STATUS_CHANGED,
    PAYOUT_STATUS_CHANGED,
    SETTLEMENT_RULE_PAYOUT_EXECUTION_FAILED,
    VIRTUAL_ACCOUNT_CREDIT_RECEIVED,
} from 'constants/webhookEventTypes';

const isServer = typeof window === 'undefined';
useStaticRendering(isServer);
configure({
    enforceActions: 'observed',
});

class WebhookStore {
    constructor(rootStore) {
        this.rootStore = rootStore;
        this.GenericSuccessStore = new ModalStore();
        this.GenerateWebhookModal = new ModalStore();
    }

    @observable
    form = {
        webhookUrl: '',
        transfers: false,
        recurringPayments: false,
        refunds: false,
        vrpConsent: false,
        vrpPaymentInit: false,
        aisBankOutage: false,
        sipBankOutage: false,
        paymentStatusChanged: false,
    };

    defaultForm = {
        webhookUrl: '',
        transfers: false,
        recurringPayments: false,
        refunds: false,
        vrpConsent: false,
        vrpPaymentInit: false,
        aisBankOutage: false,
        sipBankOutage: false,
        paymentStatusChanged: false,
    };

    eventTypeMap = {
        transfers: TRANSFER_STATUS_CHANGED,
        recurringPayments: STANDING_ORDER_STATUS_CHANGED,
        refunds: REFUND_STATUS_CHANGED,
        vrpConsent: VRP_CONSENT_STATUS_CHANGED,
        vrpPaymentInit: VRP_STATUS_CHANGED,
        aisBankOutage: BANK_AIS_OUTAGE_STATUS_CHANGED,
        sipBankOutage: BANK_SIP_OUTAGE_STATUS_CHANGED,
        paymentStatusChanged: PAYMENT_STATUS_CHANGED,
        payoutStatusChanged: PAYOUT_STATUS_CHANGED,
        settlementRulePayoutExecutionFailed: SETTLEMENT_RULE_PAYOUT_EXECUTION_FAILED,
        virtualAccountPayIns: VIRTUAL_ACCOUNT_CREDIT_RECEIVED,
    };

    eventTypes = Object.keys(this.eventTypeMap);

    @observable
    loading = false;
    @observable
    testButtonLoading = false;
    @observable
    webhookConfig = {
        url: '',
        type: [],
    };
    @observable
    isFormValid = false;
    @observable
    disableTestButton = true;

    @action
    changeForm = (name, value) => {
        this.form[name] = value;
        this.isFormValid = this.validateForm();
        this.disableTestButton = true;
    };

    handleChange = (event) => {
        this.changeForm(event.target.name, event.target.value);
    };

    @action
    handleCheck = (event) => {
        this.changeForm(event.target.name, event.target.checked);
    };

    validateForm = () => {
        const type = [];
        this.eventTypes.forEach((t) => {
            if (this.form[t]) type.push(t);
        });

        if (!this.form.webhookUrl || type.length === 0) {
            return false;
        }

        return (
            this.webhookConfig.url !== this.form.webhookUrl ||
            !isEqual(this.webhookConfig.type, type)
        );
    };

    @action
    handleSubmit = flow(
        function* (memberId) {
            this.loading = true;
            try {
                const type = [];
                this.eventTypes.forEach((t) => {
                    if (this.form[t]) type.push(this.eventTypeMap[t]);
                });
                const email = encodeURIComponent(this.rootStore.memberEmail);
                const scope = this.rootStore.scope;

                yield axios.post(
                    `/api/member/${memberId}/webhook-config?email=${email}&scope=${scope}`,
                    {
                        url: this.form.webhookUrl,
                        type,
                    },
                );

                yield this.handleFetch(memberId);
                this.isFormValid = false;
                this.GenericSuccessStore.openModal();
            } catch (e) {
                console.error(e);
                this.rootStore.GenericErrorStore.openErrorModal(e);
            } finally {
                this.loading = false;
            }
        }.bind(this),
    );

    @action
    handleFetch = flow(
        function* (memberId) {
            this.loading = true;
            try {
                const response = yield axios.get(`/api/member/${memberId}/webhook-config`);
                if (response.data.config !== null) {
                    this.webhookConfig = {
                        url: response.data.config.url,
                        type: response.data.config.type.map((t) => invert(this.eventTypeMap)[t]),
                    };

                    this.form.webhookUrl = this.webhookConfig.url;
                    this.webhookConfig.type.forEach((t) => {
                        this.form[t] = true;
                    });
                    this.disableTestButton = false;

                    this.defaultForm = {
                        ...this.form,
                    };
                }
            } catch (e) {
                console.error(e);
            } finally {
                this.loading = false;
            }
        }.bind(this),
    );

    @action
    handleGenerate = flow(
        function* (memberId) {
            this.testButtonLoading = true;
            try {
                const type = [];
                this.webhookConfig.type.forEach((t) => {
                    type.push(this.eventTypeMap[t]);
                });
                const email = encodeURIComponent(this.rootStore.memberEmail);
                const scope = this.rootStore.scope;
                yield axios.post(
                    `/api/member/${memberId}/generate-webhook-event?email=${email}&scope=${scope}`,
                    {
                        type,
                    },
                );

                this.GenerateWebhookModal.openModal();
            } catch (e) {
                console.error(e);
                this.rootStore.GenericErrorStore.openErrorModal(e);
            } finally {
                this.testButtonLoading = false;
            }
        }.bind(this),
    );
}

export default WebhookStore;
